import React, { useEffect, Suspense } from "react";
import { NavLink } from "react-router-dom";
import "../styles/home.css";

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { EffectComposer, Bloom } from "@react-three/postprocessing"
import Cyberorb from "../Components/Cyberorb";

const Home = () => {

  const currentDate = new Date()
  const currentDay = currentDate.getDate();
  const formattedDay = currentDay < 10 ? `0${currentDay}` : currentDay;
  const currentMonth = currentDate.toLocaleDateString('en-US', {
    month: 'long'
  }).toUpperCase();

  return (
    <div className="home_page__container">
      <div className="contents--container">
        {/* Desktop Display */}
        <div className="home-left--container">
          <div className="home-availability--container">
            <div className="home-availability--text">
              AVAILABLE FOR WORK
            </div>
            <div className="home--diagonal-line">
              <svg width='100%' height='100%' viewBox='0 0 100 100' preserveAspectRatio='none' stroke-width="2">
                <line x1="0" y1="100" x2="100" y2="0" vector-effect="non-scaling-stroke" stroke="white" />
              </svg>
            </div>
            <div className="home-availability--date">
              <h1>{formattedDay}</h1>
              <h2>{currentMonth}</h2>
            </div>
          </div>
          <div className="home_intro__container">
            <h1>ARIANNA ESCOBAR-REYES</h1>
            <p>I create user-friendly products by prioritizing accessibility and comprehensible graphics</p>
            <div className="home_intro__button">
              <NavLink to="/about">
                LEARN MORE
                <ArrowOutwardIcon className="home_intro__arrow" />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="home-right--container">
          <div className="home-cyberorb--model">
            {/* <canvas id="canvas3d"></canvas> Spline Design Canvas */}
            <Canvas camera={{ position: [0, 0, 5], zoom: 3 }}>
              <ambientLight color={0xF7C25C} intensity={10} />
              <Suspense fallback={null}>
                <OrbitControls enableZoom={false} />
                <Cyberorb />
              </Suspense>
              <EffectComposer>
                <Bloom
                  strength={1}
                  luminanceThreshold={0.2}
                  luminanceSmoothing={0.2}
                />
              </EffectComposer>
            </Canvas>
          </div>
          <div className="home-positions--container">
            <h1>SOFTWARE ENGINEER</h1>
            <h1>FULL STACK DEVELOPER</h1>
          </div>
        </div>
        {/* Mobile Display */}
        <div className="home-mobile--container">
          <div className="home-cyberorb--model">
            {/* <canvas id="canvas3d__mobile"></canvas> */}
            <Canvas camera={{ position: [0, 0, 5], zoom: 3 }}>
              <ambientLight color={0xF7C25C} intensity={10} />
              <Suspense fallback={null}>
                <OrbitControls enableZoom={false} />
                <Cyberorb />
              </Suspense>
              <EffectComposer>
                <Bloom
                  strength={1}
                  luminanceThreshold={0.2}
                  luminanceSmoothing={0.2}
                />
              </EffectComposer>
            </Canvas>
          </div>
          <div className="home_intro__container">
            <h1 className="home__full_name">ARIANNA ESCOBAR-REYES</h1>
            <h1 className="home__shortened_name">ARIANNA ESCOBAR</h1>
            <p>I create user-friendly products by prioritizing accessibility and comprehensible graphics</p>
            <div className="home_intro__button">
              <NavLink to="/about">
                LEARN MORE
                <ArrowOutwardIcon className="home_intro__arrow" />
              </NavLink>
            </div>
          </div>
          <div className="home-positions--container">
            <h1>SOFTWARE ENGINEER</h1>
            <h1>FULL STACK DEVELOPER</h1>
          </div>
          <div className="home-availability--container">
            <div className="home-availability--text">
              AVAILABLE FOR WORK
            </div>
            <div className="home--diagonal-line">
              <svg width='100%' height='100%' viewBox='0 0 100 100' preserveAspectRatio='none' stroke-width="2">
                <line x1="0" y1="100" x2="100" y2="0" vector-effect="non-scaling-stroke" stroke="white" />
              </svg>
            </div>
            <div className="home-availability--date">
              <h1>{formattedDay}</h1>
              <h2>{currentMonth}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;