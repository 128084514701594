import React from "react";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close'

import "../styles/modalimage.css"


const ModalImage = ({ urls, imageIndex, handleImagePrevButton, handleImageNextButton, setModalImageOpen }) => {

  return (
    <div className="modalimage container">
      <button
        className="modalimage close_button"
        onClick={() => setModalImageOpen(false)}
      >
        <CloseIcon className="close_icon" />
      </button>
      <div className="modalimage content">
        <img src={urls[imageIndex]} />
        <div className="modalimage pagination">
          <button
            className={imageIndex === 0 ? "disabled" : ""}
            onClick={() => handleImagePrevButton()}
          >
            <ArrowBackIcon classname="arrow_icon"/>
          </button>
          <h1>PHOTO {imageIndex + 1} of {urls.length}</h1>
          <button
            className={imageIndex === urls.length - 1 ? "disabled" : ""}
            onClick={() => handleImageNextButton()}
          >
            <ArrowForwardIcon className="arrow_icon"/>
          </button>
        </div>
      </div>
    </div>
  );

}

export default ModalImage;