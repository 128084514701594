/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 cyber_orb.glb 
Author: Tycho Magnetic Anomaly (https://sketchfab.com/Tycho_Magnetic_Anomaly)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/cyber-orb-8a81aa847da74a5cb36bd8ba13f6ae5e
Title: Cyber Orb
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('cyber_orb.glb')
  const { actions } = useAnimations(animations, group)


  materials["shell"]["metalness"] = 0.3
  materials["frame"]["metalness"] = 0.3

  materials["shell"]["ior"] = 100

  console.log(materials)
  console.log("Shell", materials["shell"])

  useEffect(() => {
    console.log(animations);

    if (animations && animations.length > 0) {
      const animationName = animations[0].name;
      const action = actions[animationName];
      
      if (action) {
        action.play();
      }
    }
  }, [actions, animations]);


  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
          <group name="root">
            <group name="GLTF_SceneRootNode" rotation={[Math.PI / 2, 0, 0]}>
              <group name="Sphere001_0">
                <mesh name="Object_4" geometry={nodes.Object_4.geometry} material={materials.shell} />
              </group>
              <group name="Sphere002_1" scale={0.72}>
                <mesh name="Object_6" geometry={nodes.Object_6.geometry} material={materials.material} />
              </group>
              <group name="Sphere003_2" />
              <group name="Sphere004_3">
                <mesh name="Object_9" geometry={nodes.Object_9.geometry} material={materials.frame} />
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('cyber_orb.glb')
