import React, { Suspense } from "react";
import { NavLink } from "react-router-dom";
import "../styles/notfound.css"

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { EffectComposer, Bloom } from "@react-three/postprocessing"
import Cyberorb from "../Components/Cyberorb";

const NotFound = () => {

  return (
    <div className="notfound_page__container">
      <div className="notfound__content_container">
        <div className="notfound header">
          <div className="notfound heading">
            <h1>4</h1>
            <div className="notfound_cyberorb__model">
              <Canvas camera={{ position: [0, 0, 5], zoom: 3 }}>
                <ambientLight color={0xF7C25C} intensity={10} />
                <Suspense fallback={null}>
                  <OrbitControls enableZoom={false} />
                  <Cyberorb />
                </Suspense>
                <EffectComposer>
                  <Bloom
                    strength={1}
                    luminanceThreshold={0.2}
                    luminanceSmoothing={0.2}
                  />
                </EffectComposer>
              </Canvas>
            </div>
            <h1>4</h1>
          </div>
          <h2 className="notfound subheading">
            PAGE NOT FOUND
          </h2>
        </div>
        <div className="notfound body">
          <p>Yikes! Looks like this page doesn't exist in my cyberverse.</p>
          <div className="button_container">
            <NavLink to="/">
              TRAVEL HOME
              <ArrowOutwardIcon className="arrow" />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;