import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../styles/navbar.css";

const Navbar = () => {

  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuState = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className="navbar--container">
        <nav>
          <div className="logo--container">
            <a className="logo" href="https://www.ariannaescobar.com/">Arianna Escobar-Reyes</a>
          </div>
          <div className="nav-left-slant">
            <svg width="100px" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
              <path fill="#000" d="
              M0,100 
              L100,100 
              C50,100, 25,0, 0,0 "
              />
            </svg>
          </div>
          <ul className="navbar_contents__desktop">
            <li>
              <NavLink to="/">HOME</NavLink>
            </li>
            <li>
              <NavLink to="/about">ABOUT</NavLink>
            </li>
            <li>
              <NavLink to="/works" >WORKS</NavLink>
            </li>
          </ul>
          <div className="nav-right-slant">
            <svg width="100px" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
              <path fill="#000" d="
              M100,100
              L0,100
              C50,100 75,0, 100,0
            "/>
            </svg>
          </div>
          <div className="contact__container">
            <NavLink className="nav__contact" to="/contact">CONTACT</NavLink>
            <div className="contact__burger_menu">
              <div
                className="contact__burger_bars_container"
                onClick={() => handleMenuState()}
              >
                <div 
                  className="contact__burger_bar"
                  style={{
                    transform: menuOpen ? 'translate(0, 12px) rotate(-45deg)' : 'none'
                  }}
                />
                <div 
                  className="contact__burger_bar" 
                  style={{
                    opacity: menuOpen ? '0' : '1'
                  }}
                />
                <div 
                  className="contact__burger_bar"
                  style={{
                    transform: menuOpen ? 'translate(0, -12px) rotate(45deg)' : 'none'
                  }}
                />
              </div>

              {menuOpen &&
                <ul className="navbar_contents__mobile">
                  <li>
                    <NavLink onClick={() => handleMenuState()} to="/">HOME</NavLink>
                  </li>
                  <li>
                    <NavLink onClick={() => handleMenuState()} to="/about">ABOUT</NavLink>
                  </li>
                  <li>
                    <NavLink onClick={() => handleMenuState()} to="/works" >WORKS</NavLink>
                  </li>
                  <li>
                    <NavLink onClick={() => handleMenuState()} to="/contact" >CONTACT</NavLink>
                  </li>
                </ul>
              }
            </div>
          </div>
        </nav>
      </div>
      {menuOpen && <div className="navbar__shadow_filter" />}
    </>
  );
};

export default Navbar;
