import React, { useEffect, useState } from "react";
import "../styles/works.css";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import ModalImage from "../Components/ModalImage";

const Works = () => {

  const [currentCellIndex, setCurrentCellIndex] = useState(0);
  const [currentCellSize, setCurrentCellSize] = useState(600);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalImageOpen, setModalImageOpen] = useState(false);


  const cellInfo = [
    {
      title: "MY FIRST E-PORTFOLIO",
      months: "JAN - FEB",
      year: "2024",
      techStack: ["ReactJS", "ThreeJS", "Figma"],
      images: [
        "/assets/work_images/eportfolio_v1/figma_process.png",
        "/assets/work_images/eportfolio_v1/work_page_sketch.jpg",
        "/assets/work_images/eportfolio_v1/initial_works_page.jpg",
        "/assets/work_images/eportfolio_v1/logo_process.png",
      ],
      description: "I developed my e-portfolio to showcase my past experiences and programming skills. This is my first experience using Three.js to create an interactive 3D model."
    },
    {
      title: "TEAMWORK TOOL UI/UX REDESIGN",
      months: "AUG - DEC",
      year: "2023",
      techStack: ["ReactJS", "PHP", "MySQL"],
      images: [
        "/assets/work_images/teamwork_tool/home_page.png",
        "/assets/work_images/teamwork_tool/history_page.png",
        "/assets/work_images/teamwork_tool/library_page.png",
        "/assets/work_images/teamwork_tool/add_survey.png",
        "/assets/work_images/teamwork_tool/rubric_criteria.jpg",
        "/assets/work_images/teamwork_tool/rubric_levels.jpg",
        "/assets/work_images/teamwork_tool/extend_survey.png",
      ],
      description: "I redesigned and implemented the new UI for Teamwork Tool, a surveying website used by University at Buffalo instructors to ensure fair grading in group work."
    },
    {
      title: "EXCALIBUR AUCTIONS WEBSITE",
      months: "FEB - MAY",
      year: "2023",
      techStack: ["HTML/CSS", "JavaScript", "Flask"],
      images: [
        "/assets/work_images/excalibur_auctions/home_page.jpg",
        "/assets/work_images/excalibur_auctions/create_auction.jpg",
        "/assets/work_images/excalibur_auctions/login_register_page.jpg",
        "/assets/work_images/excalibur_auctions/profile_page.jpg",
      ],
      description: "For my first front-end project, I designed and implemented a live auctioning website that allows users to big and create auctions in real-time."
    },
    {
      title: "BACKGROUND REMOVER @ lookthepart.tv",
      months: "SEPT - DEC",
      year: "2022",
      techStack: ["Python", "PyTorch", "Keras"],
      images: [
        "/assets/work_images/ltp_background_remover/background_before_after1.png",
        "/assets/work_images/ltp_background_remover/background_before_after2.png",
        "/assets/work_images/ltp_background_remover/background_before_after3.png",
        "/assets/work_images/ltp_background_remover/background_before_after4.jpg",
        "/assets/work_images/ltp_background_remover/background_before_after5.png",
      ],
      description: "I developed a background remover tool to improve the process of identifying clothing in photos."
    },
    {
      title: "MAZE SOLVER (AI ALGORITHMS)",
      months: "SEPT - DEC",
      year: "2022",
      techStack: ["Python", "Matplotlib", "NumPy"],
      images: [
        "/assets/work_images/maze_solver/bfs_search.png",
        "/assets/work_images/maze_solver/dfs_search.png",
        "/assets/work_images/maze_solver/greedy_search.png",
        "/assets/work_images/maze_solver/astar_search.png",
      ],
      description: "Implemented and animated maze solving to demonstrate the efficiency of using four distinct search algorithms."
    },


  ]

  const cellSize = currentCellSize;
  const numberOfCells = cellInfo.length;
  const transformZValue = Math.round((cellSize / 2) / Math.tan(Math.PI / numberOfCells));
  const cellRotationDegree = 360 / numberOfCells;

  const handleCarouselPrevButton = () => {
    setCurrentCellIndex(currentCellIndex - 1);
    setCurrentImageIndex(0);
  }

  const handleCarouselNextButton = () => {
    setCurrentCellIndex(currentCellIndex + 1);
    setCurrentImageIndex(0);
  }

  const handleImagePrevButton = () => {
    setCurrentImageIndex(currentImageIndex - 1);
  }

  const handleImageNextButton = () => {
    setCurrentImageIndex(currentImageIndex + 1);
  }

  return (
    <>
      <div className="works_page__container">
        <div className="works__content_container">
          <div className="carousel__prev_container">
            <button className="carousel__cell_prev" onClick={() => handleCarouselPrevButton()}>
              <ArrowBackIcon className="arrow_icon" />
            </button>
          </div>
          <div className="carousel__container">
            <div
              className="scene"
              id="scene"
              style={{ width: `${cellSize}px` }}
            >
              <div
                className="carousel"
                style={{
                  transform: `translateZ(-${transformZValue}px) rotateY(${currentCellIndex / numberOfCells * -360}deg)`
                }}
              >
                {cellInfo && cellInfo.map((data, index) => (
                  <div
                    className="carousel__cell"
                    key={index}
                    id="carousel__cell"
                    style={{
                      boxShadow: index !== (((currentCellIndex % numberOfCells) + numberOfCells) % numberOfCells) ? "none" : "0px 0px 10px #7d97ff",
                      opacity: index !== (((currentCellIndex % numberOfCells) + numberOfCells) % numberOfCells) ? 0.5 : 1,
                      transform: `rotateY(${index * cellRotationDegree}deg) translateZ(${transformZValue}px)`,
                      width: `${cellSize - 20}px`
                    }}
                  >
                    <div className="carousel__cell_content">
                      <div className="carousel__cell_header">
                        <div className="carousel__cell_timeline">
                          <h1>{data.year}</h1>
                          <h2>{data.months}</h2>
                        </div>
                        <div className="carousel__cell_headings">
                          <div className="carousel__cell_title">
                            {data.title}
                          </div>
                          <div className="carousel__cell_techstack">
                            {
                              Object.values(data.techStack).map((techName) => (
                                <h1>{techName}</h1>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                      <div
                        className="carousel__cell_images"
                        style={{
                          backgroundImage: index !== (((currentCellIndex % numberOfCells) + numberOfCells) % numberOfCells) ? `url(${data.images[0]})` : `url(${data.images[currentImageIndex]})`,
                          backgroundPosition: "center center",
                          backgroundSize: "cover",
                        }}
                      >
                      </div>
                      <div className="carousel__cell_image_buttons">
                        <div className="carousel__cell_pagination">
                          <button
                            className={"carousel__image_prev_button" + ((index !== (((currentCellIndex % numberOfCells) + numberOfCells) % numberOfCells) || currentImageIndex === 0) ? " disabled" : "")}
                            onClick={() => handleImagePrevButton()}
                          >
                            <ArrowBackIcon className="arrow_icon" />
                          </button>
                          <h1
                            style={{
                              borderRadius: (index !== (((currentCellIndex % numberOfCells) + numberOfCells) % numberOfCells) || currentImageIndex === 0) ? "5px 0px 0px 5px" : ((data.images && data.images.length === 0) || (index !== (((currentCellIndex % numberOfCells) + numberOfCells) % numberOfCells) || currentImageIndex === data.images.length - 1) ? "0px 5px 5px 0px" : "0px")
                            }}
                          >
                            {index !== (((currentCellIndex % numberOfCells) + numberOfCells) % numberOfCells) ?
                              `PHOTO 0 of ${data.images.length}`
                              : `PHOTO ${currentImageIndex + 1} of ${data.images.length}`
                            }
                          </h1>
                          <button
                            className={"carousel__image_next_button" + ((data.images && data.images.length === 0) || (index !== (((currentCellIndex % numberOfCells) + numberOfCells) % numberOfCells) || currentImageIndex === data.images.length - 1) ? " disabled" : "")}
                            onClick={() => handleImageNextButton()}
                          >
                            <ArrowForwardIcon className="arrow_icon" />
                          </button>
                        </div>
                        <button
                          className="carousel__image_fullscreen_button"
                          onClick={() => setModalImageOpen(true)}
                        >
                          <FullscreenIcon className="fullscreen_icon" />
                        </button>
                      </div>
                      <div className="carousel__cell_description">
                        {data.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="carousel__next_container">
            <button className="carousel__cell_next" onClick={() => handleCarouselNextButton()}>
              <ArrowForwardIcon className="arrow_icon" />
            </button>
          </div>
          <div className="works__carousel_buttons_mobile">
            <div className="carousel__prev_container">
              <button className="carousel__cell_prev" onClick={() => handleCarouselPrevButton()}>
                <ArrowBackIcon className="arrow_icon" />
              </button>
            </div>
            <div className="carousel__next_container">
              <button className="carousel__cell_next" onClick={() => handleCarouselNextButton()}>
                <ArrowForwardIcon className="arrow_icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {isModalImageOpen && (
        <ModalImage
          urls={cellInfo[currentCellIndex % numberOfCells].images}
          imageIndex={currentImageIndex}
          handleImagePrevButton={handleImagePrevButton}
          handleImageNextButton={handleImageNextButton}
          setModalImageOpen={setModalImageOpen}
        />
      )}
    </>
  );
};

export default Works;
