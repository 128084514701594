import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/about.css"

const About = () => {

  return (
    <div className="about_page__container">
      <div className="about__content_container">
        <div className="about__description_container">
          <h1>¡BIENVENIDOS!</h1>
          <p>
          I’m a first-gen Mexican-Colombian from New York City
          and a software engineer who loves to develop creative
          graphics. I’ve collaborated with clients to build 
          front-end and back-end solutions tailored to their needs.
          </p>

          <p>
          Outside of work, I spend my free time drawing, gardening,
          and collecting antiques. I’m passionate about fashion,
          interior design, and Batman lore. To learn more about me,
          you can contact me <NavLink to="/contact">here.</NavLink>
          </p>
        </div>
        <div className="about__image_container">  
          <div className="about_aspect_ratio"/>
          <div 
            className="about_pfp"
            style={{
              backgroundImage: `url("/assets/about_profile.jpg")`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
           }}
          />
        </div>
      </div>
    </div>
  );
};

export default About;
